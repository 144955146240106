import React from 'react';
import styled from 'styled-components';

import { Show } from '@core';
import { ImageCore, Text } from '@components';
import { query } from '@shared/style-helpers';

import CountryCard from './country-card';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  @media ${query.minTabletLarge} {
    flex-direction: row;
  }
`;

const CardWrapper = styled.div`
  @media ${query.minTabletLarge} {
    width: 33.333%;
  }
`;

const CountryRatings = ({ title, image, description, countries, lowest }) => (
  <React.Fragment>
    <ImageCore
      src={image.src}
      alt={image.alt}
      mx="auto"
      mb={10}
      maxWidth={{ _: '70%', lg: '100%' }}
    />
    <Text.Heading2 textAlign="center">{title}</Text.Heading2>
    <Show.DesktopAndUp>
      <Text.Body1 textAlign="center" mt={32}>
        {description}
      </Text.Body1>
    </Show.DesktopAndUp>
    <CardsContainer>
      {countries.map((countryInfo, index) => (
        <CardWrapper key={index}>
          <CountryCard countryInfo={countryInfo} lowest={lowest} />
        </CardWrapper>
      ))}
    </CardsContainer>
  </React.Fragment>
);

export default CountryRatings;
