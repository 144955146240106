import React from 'react';
import styled from 'styled-components';

import { query } from '@shared/style-helpers';
import { sortDataByKey } from '@shared/helpers';
import { SectionContainerSC as SectionContainer } from '@components';

import { CountryRatings } from './components';

const PositioningContainer = styled.div`
  position: relative;
  padding: 42px 0;
  top: 100px;

  @media ${query.minDesktop} {
    top: -120px;
    margin-bottom: -212px;
  }
`;

const CountryRatingsSection = ({
  titleHighest,
  titleLowest,
  imageHighest,
  imageLowest,
  description,
  countryData,
}) => {
  const countryListInfo = sortDataByKey(
    [...countryData.filter(({ adoption_primary }) => adoption_primary)], // filtering out null values
    'adoption_primary',
    true
  );
  const countriesHighest = countryListInfo.slice(0, 3);
  const countriesLowest = countryListInfo.reverse().slice(0, 3).reverse();

  return (
    <PositioningContainer>
      <SectionContainer wrapperProps={{ px: { _: 24, lg: 40 }, pt: 0 }}>
        <CountryRatings
          title={titleHighest}
          image={imageHighest}
          description={description}
          countries={countriesHighest}
          lowest={false}
        />
        <CountryRatings
          title={titleLowest}
          image={imageLowest}
          description={description}
          countries={countriesLowest}
          lowest={true}
        />
      </SectionContainer>
    </PositioningContainer>
  );
};

export default CountryRatingsSection;
