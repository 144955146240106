import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { query } from '@shared/style-helpers';
import { Text } from '@components';
import { useI18nCountries } from '@hooks';

const CardContainer = styled.div`
  font-family: ${({ theme }) => theme?.fonts?.default};
  position: relative;
  text-align: center;
  background-color: #ffffff;
  padding: 80px 30px;
  margin: 40px 0;

  @media ${query.minTabletLarge} {
    margin: 40px 12px;
  }
`;

const PositionNumberContainer = styled.div`
  && {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background-color: #daecef;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CountrySection = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CountryFlag = styled.div`
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  background-image: ${({ image }) => `url(${image})`};
  flex-shrink: 0;
`;

const AdoptionSection = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${({ lowest }) => (lowest ? '#ff9190' : '#98e8aa')};
  max-height: 64px;
  padding: 18px;
  margin-top: 10px;
`;

const shortenName = (country) => {
  let str = country;
  if (str.split(' ').length === 3) {
    str = str.match(/[A-Z]/g, '').join('');
  }
  return str;
};

const CountryCard = ({ countryInfo, lowest }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { i18nCountries } = useI18nCountries(language);

  const { iso, position, adoption_primary } = countryInfo;

  const flagImage = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${iso.toLowerCase()}.svg`;

  return (
    <CardContainer>
      <PositionNumberContainer>
        <Text.Heading2 $fontWeight={700}>{position}</Text.Heading2>
      </PositionNumberContainer>
      <CountrySection>
        <CountryFlag image={flagImage} />
        <Text.Subheading2>
          {i18nCountries && shortenName(i18nCountries.getName(iso, language, { select: 'alias' }))}
        </Text.Subheading2>
      </CountrySection>
      <AdoptionSection lowest={lowest}>
        <Text.Subheading2>{adoption_primary}%</Text.Subheading2>
      </AdoptionSection>
    </CardContainer>
  );
};

export default CountryCard;
